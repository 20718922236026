// Generated by Framer (716ed5b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor1 } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/xAqQJJMKulaAU8gK10ZA/Phosphor.js";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/xUCuNdICT7LqG93nhqbM/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const Phosphor1Controls = getPropertyControls(Phosphor1);

const enabledGestures = {Z16JUrydG: {hover: true}};

const cycleOrder = ["Z16JUrydG"];

const serializationHash = "framer-3W7AP"

const variantClassNames = {Z16JUrydG: "framer-v-1qpjc4t"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {Bold: "bold", Duotone: "duotone", Fill: "fill", Light: "light", Regular: "regular", Thin: "thin"}

const getProps = ({height, icon, id, link, style1, width, ...props}) => { return {...props, bFIDNepAx: humanReadableEnumMap[style1] ?? style1 ?? props.bFIDNepAx ?? "fill", BZvSsXzNb: link ?? props.BZvSsXzNb, rkMP6QUgR: icon ?? props.rkMP6QUgR ?? "FacebookLogo"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;icon?: string;link?: string;style1?: keyof typeof humanReadableEnumMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, rkMP6QUgR, BZvSsXzNb, bFIDNepAx, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Z16JUrydG", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Link href={BZvSsXzNb}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1qpjc4t", className, classNames)} framer-15ep43e`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"Z16JUrydG"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"Z16JUrydG-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1it8i0j-container"} layoutDependency={layoutDependency} layoutId={"CskccQ5om-container"}><Phosphor color={"var(--token-fe0a76d1-a2f5-44b4-8714-e201073a8f91, rgb(115, 135, 160))"} height={"100%"} iconSearch={"House"} iconSelection={rkMP6QUgR} id={"CskccQ5om"} layoutId={"CskccQ5om"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={bFIDNepAx} width={"100%"} {...addPropertyOverrides({"Z16JUrydG-hover": {color: "rgb(169, 168, 162)"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-3W7AP[data-border=\"true\"]::after, .framer-3W7AP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3W7AP.framer-15ep43e, .framer-3W7AP .framer-15ep43e { display: block; }", ".framer-3W7AP.framer-1qpjc4t { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 32px; }", ".framer-3W7AP .framer-1it8i0j-container { flex: none; height: 32px; position: relative; width: 32px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-3W7AP.framer-1qpjc4t { gap: 0px; } .framer-3W7AP.framer-1qpjc4t > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-3W7AP.framer-1qpjc4t > :first-child { margin-left: 0px; } .framer-3W7AP.framer-1qpjc4t > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"QcCBm9NcS":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"rkMP6QUgR":"icon","BZvSsXzNb":"link","bFIDNepAx":"style1"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerltIjqS8dZ: React.ComponentType<Props> = withCSS(Component, css, "framer-3W7AP") as typeof Component;
export default FramerltIjqS8dZ;

FramerltIjqS8dZ.displayName = "Social Icon Copy";

FramerltIjqS8dZ.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerltIjqS8dZ, {rkMP6QUgR: Phosphor1Controls?.["iconSelection"] && {...Phosphor1Controls["iconSelection"], defaultValue: "FacebookLogo", description: undefined, hidden: undefined, title: "Icon"}, BZvSsXzNb: {title: "Link", type: ControlType.Link}, bFIDNepAx: Phosphor1Controls?.["weight"] && {...Phosphor1Controls["weight"], defaultValue: "fill", description: undefined, hidden: undefined, title: "Style"}} as any)

addFonts(FramerltIjqS8dZ, [...PhosphorFonts])